<template>
  <section id="dashboard-mensuel">
    <b-row class="match-height mb-2">
      <b-col
        lg="2"
        md="2"
      >
        <v-select
          v-model="selectedTrimestre"
          :reduce="label => label.code"
          label="label"
          :options="[
            {label: 'Q1', code: '1'},
            {label: 'Q2', code: '2'},
            {label: 'Q3', code: '3'},
            {label: 'Q4', code: '4'},
          ]"
          @input="changeMonth"
        />
      </b-col>
      <b-col
        lg="1"
        md="1"
      >
        <datepicker
          v-model="monthSelected"
          :minimum-view="'year'"
          :maximum-view="'year'"
          format="yyyy"
          :language="fr"
          placeholder="Choix année"
          @input="changeMonth"
        />
      </b-col>
    </b-row>
    <b-row>

      <!-- Browser States Card -->
      <b-col
        lg="4"
        md="3"
      >
        <sales-overview
          :revenue-comparison-line="revenueComparisonLine"
          :goal-overview="goalOverViewValue"
          :chart-options="analyticsData.revenueComparisonLineTrimestre.chartOptions"
        />
      </b-col>
      <!--/ Browser States Card -->

      <!-- Goal Overview Card -->
      <b-col
        lg="3"
        md="3"
      >
        <objectif-goal-overview
          :goal-overview="goalOverview"
          :projection="projection"
          :goal="goal"
          title="Objectif global mensuel"
        />

      </b-col>
      <!--/ Goal Overview Card -->

      <!-- Transaction Card -->
      <b-col
        lg="5"
        md="3"
      >
        <product-overview
          :product-sales="productSales"
          :height-chart="heightChart"
        />
      </b-col>
      <!--/ Transaction Card -->
    </b-row>

    <b-row class="match-height">
      <b-col
        lg="6"
        md="3"
      >
        <country-sales-overview
          :customers-data="customersData"
          :height-chart="heightChartSecteur"
        />
      </b-col>

      <b-col
        lg="3"
        md="3"
      >
        <b-row>
          <b-col>
            <objectif-goal-overview
              :goal-overview="goalOverviewEst"
              :projection="projectionEst"
              :goal="goalEst"
              title="Perf Est"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <objectif-goal-overview
              :goal-overview="goalOverviewOuest"
              :projection="projectionOuest"
              :goal="goalOuest"
              title="Perf Ouest"
            />
          </b-col>
        </b-row>
      </b-col>
      <b-col
        lg="3"
        md="3"
      >
        <b-row>
          <b-col>
            <objectif-goal-overview
              :goal-overview="goalOverviewNordEst"
              :projection="projectionNordEst"
              :goal="goalNordEst"
              title="Perf NordEst"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <objectif-goal-overview
              :goal-overview="goalOverviewNordOuest"
              :projection="projectionNordOuest"
              :goal="goalNordOuest"
              title="Perf NordOuest"
            />
          </b-col>
        </b-row>
      </b-col>

    </b-row>
    <b-row class="match-height">
      <b-col
        lg="6"
        md="3"
      >&nbsp;
      </b-col>
      <b-col
        lg="3"
        md="3"
      >
        <b-row>
          <b-col>
            <objectif-goal-overview
              :goal-overview="goalOverviewSofamed"
              :projection="projectionSofamed"
              :goal="goalSofamed"
              title="Perf Sofamed"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <objectif-goal-overview
              :goal-overview="goalOverviewExport"
              :projection="projectionExport"
              :goal="goalExport"
              title="Perf Export"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>

  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import axios from '@axios'
import vSelect from 'vue-select'
import Datepicker from 'vuejs-datepicker'
import { fr } from 'vuejs-datepicker/dist/locale'
import analyticsData from '@/views/dashboard/analyticsData'
import ObjectifGoalOverview from './ObjectifOverview.vue'
import ProductOverview from './ProductOverview.vue'
import SalesOverview from '@/views/dashboard/hebdo/SalesOverview.vue'
import CountrySalesOverview from '@/views/dashboard/hebdo/CountrySalesOverview.vue'

export default {
  components: {
    BRow,
    BCol,
    Datepicker,
    ObjectifGoalOverview,
    SalesOverview,
    CountrySalesOverview,
    ProductOverview,
    vSelect,
  },
  data() {
    return {
      fr,
      revenueComparisonLine: {},
      goalOverViewValue: 0,
      goalOverview: [0],
      projection: 0,
      goal: 0,
      selectedTrimestre: 1,
      goalOverviewEst: [0],
      projectionEst: 0,
      goalEst: 0,
      goalOverviewNordEst: [0],
      projectionNordEst: 0,
      goalNordEst: 0,
      goalOverviewOuest: [0],
      projectionOuest: 0,
      goalOuest: 0,
      goalOverviewNordOuest: [0],
      projectionNordOuest: 0,
      goalNordOuest: 0,
      goalOverviewSofamed: [0],
      projectionSofamed: 0,
      goalSofamed: 0,
      goalOverviewExport: [0],
      projectionExport: 0,
      goalExport: 0,

      monthSelected: new Date(),
      productStock: [],
      customersData: {},
      productSales: {},
      analyticsData,
      heightChart: 450,
      heightChartSecteur: 500,
      settings: { // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: 0.60,
      },
    }
  },
  computed: {
    scrollbarTag() { return this.$store.getters.scrollbarTag },
  },
  created() {
    // Calcul du trimestre en cours
    const now = new Date()
    this.selectedTrimestre = Math.ceil((now.getMonth() + 1) / 3)

    this.refreshStats()

    // eslint-disable-next-line no-undef
    /* axios.get('sales-goals/sales-product-stock')
      .then(response => {
        this.productStock = response.data
      }).catch(error => { console.log(error) }) */
  },

  methods: {
    changeMonth() {
      this.refreshStats()
    },

    getQuarter(date = new Date()) {
      return Math.floor(date.getMonth() / 3 + 1)
    },

    daysLeftInQuarter(date = new Date()) {
      const quarter = this.getQuarter(date)

      const nextQuarter = new Date()

      if (quarter === 4) {
        nextQuarter.setFullYear(date.getFullYear() + 1, 0, 1)
      } else {
        nextQuarter.setFullYear(date.getFullYear(), quarter * 3, 1)
      }

      const ms1 = date.getTime()
      const ms2 = nextQuarter.getTime()

      return Math.floor((ms2 - ms1) / (24 * 60 * 60 * 1000))
    },
    refreshStats() {
      console.log('Trimestre ')
      console.log(this.selectedTrimestre)
      console.log(this.monthSelected.getFullYear())

      // eslint-disable-next-line no-undef
      axios.get(`sales-goals/sales-product-by-quarter/${this.monthSelected.getFullYear()}/${this.selectedTrimestre}`)
        .then(response => {
          console.log(response)
          this.productSales.series = response.data[0].series
          this.heightChart = 450 + parseInt(this.selectedTrimestre, 10) + parseInt(this.monthSelected.getFullYear(), 10) - 2010
          console.log(this.heightChart) // très con, mais permet de forcer l'update du chart, ne pas toucher
        }).catch(error => { console.log(error) })

      // Calcul du nb de jours passés dans le trimestre
      // https://bobbyhadz.com/blog/javascript-get-date-quarter
      // Si mois en cours, sinon il faut prendre le dernier jour du mois choisi
      const now = new Date()

      // Calcul du nb jours dans le trimestre
      const quarter = Math.floor((now.getMonth() / 3))
      const firstDate = new Date(now.getFullYear(), quarter * 3, 1)
      const endDate = new Date(firstDate.getFullYear(), firstDate.getMonth() + 3, 0)
      const difference = endDate.getTime() - firstDate.getTime()
      const totalDaysInQuarter = Math.ceil(difference / (1000 * 3600 * 24))
      let totalDaysSpentInQuarter = totalDaysInQuarter

      console.log('Current quarter =')
      console.log(this.getQuarter())
      if (this.monthSelected.getFullYear() === now.getFullYear() && this.selectedTrimestre === this.getQuarter()) {
        totalDaysSpentInQuarter = totalDaysInQuarter - this.daysLeftInQuarter()
      }

      // eslint-disable-next-line no-undef
      axios.get(`sales-goals/sales-by-quarter/${this.monthSelected.getFullYear()}/${this.selectedTrimestre}`)
        .then(response => {
          this.revenueComparisonLine.series = response.data[0].series
          this.revenueComparisonLine.analyticsData = response.data[1].analyticsData
          if (response.data[1].analyticsData) {
            this.goal = parseInt(response.data[1].analyticsData.goal, 10)
          }

          this.projection = (this.revenueComparisonLine.analyticsData.thisMonth / totalDaysSpentInQuarter) * totalDaysInQuarter

          if (this.revenueComparisonLine.analyticsData.goal && this.revenueComparisonLine.analyticsData.goal > 0) {
            this.goalOverview = [((this.projection / this.revenueComparisonLine.analyticsData.goal) * 100).toFixed(0)]

            this.goalOverViewValue = parseInt(((this.projection / this.revenueComparisonLine.analyticsData.goal) * 100).toFixed(0), 10)
          } else {
            this.goalOverview = [0]
            this.goalOverViewValue = 0
          }
        })
        .catch(error => { console.log(error) })

      /**
       * EST
       */
      // eslint-disable-next-line no-undef
      axios.get(`sales-goals/sales-goals-secteur-by-quarter/Est/${this.monthSelected.getFullYear()}/${this.selectedTrimestre}`)
        .then(response => {
          this.goalEst = parseInt(response.data[0].goal, 10)
          this.projectionEst = (response.data[0].sales / totalDaysSpentInQuarter) * totalDaysInQuarter

          if (this.goalEst > 0) {
            this.goalOverviewEst = [((this.projectionEst / this.goalEst) * 100).toFixed(0)]
          } else {
            this.goalOverviewEst = [0]
          }
        })
        .catch(error => { console.log(error) })
      /**
       * NORD EST
       */
      // eslint-disable-next-line no-undef
      axios.get(`sales-goals/sales-goals-secteur-by-quarter/Nord-Est/${this.monthSelected.getFullYear()}/${this.selectedTrimestre}`)
        .then(response => {
          this.goalNordEst = parseInt(response.data[0].goal, 10)
          this.projectionNordEst = (response.data[0].sales / totalDaysSpentInQuarter) * totalDaysInQuarter

          if (this.goalNordEst > 0) {
            this.goalOverviewNordEst = [((this.projectionNordEst / this.goalNordEst) * 100).toFixed(0)]
          } else {
            this.goalOverviewNordEst = [0]
          }
        })
        .catch(error => { console.log(error) })
      /**
       * OUEST
       */
      // eslint-disable-next-line no-undef
      axios.get(`sales-goals/sales-goals-secteur-by-quarter/Ouest/${this.monthSelected.getFullYear()}/${this.selectedTrimestre}`)
        .then(response => {
          this.goalOuest = parseInt(response.data[0].goal, 10)
          this.projectionOuest = (response.data[0].sales / totalDaysSpentInQuarter) * totalDaysInQuarter

          if (this.goalOuest > 0) {
            this.goalOverviewOuest = [((this.projectionOuest / this.goalOuest) * 100).toFixed(0)]
          } else {
            this.goalOverviewOuest = [0]
          }
        })
        .catch(error => { console.log(error) })
      /**
       * NORD OUEST
       */
      // eslint-disable-next-line no-undef
      axios.get(`sales-goals/sales-goals-secteur-by-quarter/Nord-Ouest/${this.monthSelected.getFullYear()}/${this.selectedTrimestre}`)
        .then(response => {
          this.goalNordOuest = parseInt(response.data[0].goal, 10)
          this.projectionNordOuest = (response.data[0].sales / totalDaysSpentInQuarter) * totalDaysInQuarter

          if (this.goalNordOuest > 0) {
            this.goalOverviewNordOuest = [((this.projectionNordOuest / this.goalNordOuest) * 100).toFixed(0)]
          } else {
            this.goalOverviewNordOuest = [0]
          }
        })
        .catch(error => { console.log(error) })
      /**
       * EXPORT
       */
      // eslint-disable-next-line no-undef
      axios.get(`sales-goals/sales-goals-secteur-by-quarter/Export/${this.monthSelected.getFullYear()}/${this.selectedTrimestre}`)
        .then(response => {
          this.goalExport = parseInt(response.data[0].goal, 10)
          this.projectionExport = (response.data[0].sales / totalDaysSpentInQuarter) * totalDaysInQuarter

          if (this.goalExport > 0) {
            this.goalOverviewExport = [((this.projectionExport / this.goalExport) * 100).toFixed(0)]
          } else {
            this.goalOverviewExport = [0]
          }
        })
        .catch(error => { console.log(error) })
      /**
       * Sofamed
       */
      // eslint-disable-next-line no-undef
      axios.get(`sales-goals/sales-goals-secteur-by-quarter/Sofamed/${this.monthSelected.getFullYear()}/${this.selectedTrimestre}`)
        .then(response => {
          this.goalSofamed = parseInt(response.data[0].goal, 10)
          this.projectionSofamed = (response.data[0].sales / totalDaysSpentInQuarter) * totalDaysInQuarter

          if (this.goalSofamed > 0) {
            this.goalOverviewSofamed = [((this.projectionSofamed / this.goalSofamed) * 100).toFixed(0)]
          } else {
            this.goalOverviewSofamed = [0]
          }
        })
        .catch(error => { console.log(error) })

      // eslint-disable-next-line no-undef
      axios.get(`sales-goals/sales-secteur-by-quarter/${this.monthSelected.getFullYear()}/${this.selectedTrimestre}`)
        .then(response => {
          console.log(response)
          this.customersData.series = response.data[0].series
          this.customersData.analyticsData = response.data[1].analyticsData
          this.customersData.labels = response.data[1].labels
          this.analyticsData.customersPie.chartOptions.labels = this.customersData.labels
          this.heightChartSecteur = 500 + parseInt(this.selectedTrimestre, 10) + parseInt(this.monthSelected.getFullYear(), 10) - 2010 // très con, mais permet de forcer l'update du chart, ne pas toucher
        }).catch(error => { console.log(error) })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
